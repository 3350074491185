<template>
  <modal name="mHistory" height="auto" width="900" :clickToClose="false">
    <CloseModal :close="close" />
<!-- RESERVA E ENTREGA -->
    <div class="modalbox">
      <div class="modalbox-content">
        <CTabs variant="tabs" class="nav-tabs-boxed">
        <CTab title="Histórico">
          <div class="form">
            <div class="xrow" style="margin-bottom: 10px">
              <div class="col-1-4">
                <label>De</label>
                <datetime
                  input-class="form-control"
                  v-model="dataInicio"
                  type="date"
                  :max-datetime="fim"
                  placeholder="Data inicial"
                  :phrases="{ok: 'Confirmar', cancel: 'Fechar'}"
                  value-zone="local"
                  format="dd/MM/yyyy"
                />
              </div>
              <div class="col-1-4">
                <label>Até</label>
                <datetime
                  input-class="form-control"
                  v-model="dataFim"
                  type="date"
                  :min-datetime="inicio"
                  placeholder="Data final"
                  :phrases="{ok: 'Confirmar', cancel: 'Fechar'}"
                  value-zone="local"
                  format="dd/MM/yyyy"
                />
              </div>
              <div class="col-1-4">
                <label>&nbsp;</label><br>
                <button class="button button-primary" v-on:click="findAnaliseEstoque">
                  Buscar
                </button>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>Data Hora</th>
                    <th>Colaborador</th>
                    <th>Cliente</th>
                    <th>Quantidade</th>
                    <th></th>                    
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in items"  v-bind:key="item.mershandising_id + '' + item.dado + '' + item.datahora_criacao">
                    <td>{{ convertDataHora(item.datahora_criacao) }}</td>
                    <td>{{ item.colaborador_nome }}</td>
                    <td>{{ item.cliente_nome }}</td>
                    <td>{{ item.quantidade }}</td>
                    <td v-if="item.dado === 'RESERVA'">
                      <span class="label success" title="Reserva">
                        <fa-icon icon="chevron-up" />
                      </span>                      
                    </td>
                    <td v-if="item.dado === 'ENTREGA'"> 
                      <span class="label error" title="Entrega">
                        <fa-icon icon="chevron-down" />
                      </span>                      
                    </td>                    
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </CTab>
        </CTabs>
      </div>
    </div>
  </modal>
</template>

<script>
import SimpleVueValidation from "simple-vue-validator";
import CloseModal from "../../../../components/CloseModal";
import { get, post, put, del } from "../../../../helpers/apiRequest";
import moment from 'moment';

const Validator = SimpleVueValidation.Validator.create({
  templates: {
    required: "Campo de preenchimento obrigatório",
  },
});

export default {
  name: "mHistory",
  components: { CloseModal },
  props: ["item", "close", "submit", "update", "mershandisings"],
  data() {
    return {
      items: [],
      dataInicio: moment()
        .subtract(3, 'months')
        .format('YYYY-MM-DD'),
      dataFim: moment().format('YYYY-MM-DD'),
    };
  },  
  computed: {
    inicio() {
      return moment(this.dataInicio).format('YYYY-MM-DD');
    },

    fim() {
      return moment(this.dataFim).format('YYYY-MM-DD');
    },
  },
  watch: {
    "item.id"(mershandising_id) {      
        this.findAnaliseEstoque()      
    },
  },
  methods: {
    findAnaliseEstoque() {      
      get(`/admin/mershandising/analise-estoque?mershandising_id=${this.item.mershandising_id}&data_inicio=${
        this.inicio
      }&data_fim=${this.fim}`)      
      .then((json) => {        
        this.items = json;        
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
      });
    },
    convertDataHora(dataHora) {
      return moment(String(dataHora)).format('DD/MM/YYYY HH:mm')
    },
    beforeMount() {
    this.loading = true;
    
  },
  },
};
</script>

<style scoped>
.line {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pre-text {
  font-size: 10px;
}
.main-text {
  font-size: 14px;
  font-weight: bold;
}
.details {
  background-color: #fafafa;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid #ddd;
}
</style>
