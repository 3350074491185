<template>
  <modal name="mEdit" height="auto" width="900" :clickToClose="false">
    <CloseModal :close="close" />

    <div class="modalbox">
      <div class="modalbox-content">
        <CTabs variant="tabs" class="nav-tabs-boxed">
        <CTab title="Estoque">
        <div class="form" v-show="activeTab === 'dados'">
          <div class="xrow">
            <div class="col-1-2">
              <div class="form-group">
                <label>Mershandising</label>
                <select class="form-control" v-model="item.mershandising_id">
                  <option>Selecione</option>
                  <option
                    v-for="mershandising in mershandisings"
                    v-bind:key="mershandising.id"
                    :value="mershandising.id"
                    >{{ mershandising.id }} -
                    {{ mershandising.descricao }}</option
                  >
                </select>
                <div class="message">
                  {{ validation.firstError("item.mershandising_id") }}
                </div>
              </div>
            </div>
            <div class="col-1-2">
              <div class="form-group">
                <label>Quantidade</label>
                <input
                  class="form-control"
                  autocomplete="chrome-off"
                  type="number"
                  v-model="item.quantidade"
                />
                <div class="message">
                  {{ validation.firstError("item.quantidade") }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="button button-success" v-on:click="handleSubmit">
            Salvar
          </button>
          <button class="button" v-on:click="close">Fechar</button>
        </div>
        </CTab>
        </CTabs>
      </div>
    </div>
  </modal>
</template>

<script>
import SimpleVueValidation from "simple-vue-validator";
import CloseModal from "../../../../components/CloseModal";

const Validator = SimpleVueValidation.Validator.create({
  templates: {
    required: "Campo de preenchimento obrigatório",
  },
});

export default {
  name: "mEdit",
  components: { CloseModal },
  props: ["item", "close", "submit", "update", "mershandisings"],
  data() {
    return {
      filter: "",
      resultLimit: 100,
      activeTab: "dados",
    };
  },
  validators: {
    "item.mershandising_id": (value) => Validator.value(value).required(),
    "item.quantidade": (value) => Validator.value(value).required(),
  },
  computed: {},
  methods: {
    handleSubmit() {
      this.$validate().then((success) => {
        if (success) {
          if (!!this.item.id) request = this.update(this.item);
          if (!this.item.id) request = this.submit(this.item);
        }
      });
    },
  },
};
</script>

<style scoped>
.line {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pre-text {
  font-size: 10px;
}
.main-text {
  font-size: 14px;
  font-weight: bold;
}
.details {
  background-color: #fafafa;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid #ddd;
}
</style>
