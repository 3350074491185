<template>
  <modal name="mEdit" height="auto" width="900" :clickToClose="false">
    <CloseModal :close="close" />
    <div class="modalbox">
      <div class="modalbox-content">
        <CTabs variant="tabs" class="nav-tabs-boxed">
        <CTab title="Ação">
        <div class="form" v-show="activeTab === 'dados'">
          <div class="xrow">
            <div class="col-1-4">
              <div class="form-group">
                <label>ID</label>
                <input
                  class="form-control"
                  autocomplete="chrome-off"
                  v-model="item.id"
                />
                <div class="message">
                  {{ validation.firstError("item.id") }}
                </div>
              </div>
            </div>
            <div class="col-1-4">
              <div class="form-group">
                <label>Etapa</label>
                <input
                  class="form-control"
                  autocomplete="chrome-off"
                  v-model="item.requer_etapa"
                />
              </div>
            </div>
            <div class="col-1-4">
              <div class="form-group">
                <label>Valida cliente ?</label>
                <select class="form-control" v-model="item.valida_cliente">
                  <option value="1">Sim</option>
                  <option value="0">Não</option>
                </select>
              </div>
            </div>
            <div class="col-1-4">
              <div class="form-group">
                <label>Status</label>
                <select class="form-control" v-model="item.ativo">
                  <option value="1">Ativo</option>
                  <option value="0">Inativo</option>
                </select>
                <div class="message">
                  {{ validation.firstError("item.ativo") }}
                </div>
              </div>
            </div>
          </div>
          <div class="xrow">
            <div class="col-1-2">
              <div class="form-group">
                <label>Descrição</label>
                <input
                  class="form-control"
                  autocomplete="chrome-off"
                  v-model="item.descricao"
                />
                <div class="message">
                  {{ validation.firstError("item.descricao") }}
                </div>
              </div>
            </div>
            <div class="col-1-2">
              <div class="form-group">
                <label>Quantidade minima de fotos</label>
                <input
                  class="form-control"
                  autocomplete="chrome-off"
                  type="number"
                  v-model="item.qtd_min_fotos"
                />
                <div class="message">
                  {{ validation.firstError("item.qtd_min_fotos") }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            v-if="!btnUpdate.ativo"
            class="button button-success"
            v-on:click="handleSubmit"
          >
            Cadastrar
          </button>
          <button
            v-if="btnUpdate.ativo"
            class="button button-success"
            v-on:click="handleUpdate"
          >
            Atualizar
          </button>
          <button class="button" v-on:click="close">Fechar</button>
        </div>
        </CTab>
        </CTabs>
      </div>
    </div>
  </modal>
</template>

<script>
import SimpleVueValidation from "simple-vue-validator";
import vSelect from "vue-select";
import CloseModal from "../../../../components/CloseModal";

const Validator = SimpleVueValidation.Validator.create({
  templates: {
    required: "Campo de preenchimento obrigatório",
  },
});

export default {
  name: "mEdit",
  components: { vSelect, CloseModal },
  props: ["item", "close", "submit", "update", "btnUpdate"],
  data() {
    return {
      resultLimit: 100,
      activeTab: "dados",
      clientes: [],
    };
  },
  validators: {
    "item.id": (value) => Validator.value(value).required(),
    "item.descricao": (value) => Validator.value(value).required(),
    "item.qtd_min_fotos": (value) => Validator.value(value).required(),
    "item.ativo": (value) => Validator.value(value).required(),
  },
  computed: {},
  methods: {
    handleSubmit() {
      this.$validate().then((success) => {
        if (success) {
          let request = this.submit(this.item);
          request.catch((error) => {
            this.$swal({
              title: "Ops!",
              text: error.message,
              icon: "error",
            });
          });
        }
      });
    },
    handleUpdate() {
      this.$validate().then((success) => {
        if (success) {
          let request = this.update(this.item);
          request.catch((error) => {
            this.$swal({
              title: "Ops!",
              text: error.message,
              icon: "error",
            });
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.line {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pre-text {
  font-size: 10px;
}
.main-text {
  font-size: 14px;
  font-weight: bold;
}
.details {
  background-color: #fafafa;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid #ddd;
}
</style>
