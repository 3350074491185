<template>
  <modal name="mEdit" height="auto" width="900" :clickToClose="false" >
    <CloseModal :close="close" />
    <div class="modalbox">
      <div class="modalbox-content">
        <CTabs variant="tabs" class="nav-tabs-boxed">
        <CTab title="Reserva">
        <div class="form" v-show="activeTab === 'dados'">
          <div class="xrow">
            <div class="col-1-2">
              <div class="form-group">
                <label>Mershandising</label>
                <select class="form-control" v-model="item.mershandising_id">
                  <option >Selecione</option>
                  <option v-for="mershandising in mershandisings" v-bind:key="mershandising.id" :value="mershandising.id">{{mershandising.id}} - {{mershandising.descricao}}</option>
                </select>
                <div class="message">{{ validation.firstError('item.mershandising_id') }}</div>
              </div>
            </div>
            <div class="col-1-2">
              <div class="form-group">
                <label>Colaborador</label>
                <select class="form-control" v-model="item.colaborador_id"  @change="updateClientes">
                  <option v-for="colaborador in colaboradores" v-bind:key="colaborador.id" :value="colaborador.id">{{colaborador.id}} - {{colaborador.nome}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="xrow">
            <div class="col-1-2">
              <div class="form-group">
                <label for="">Clientes</label>
                <select  v-if="item.colaborador_id" class="form-control" v-model="item.cliente_id">
                  <option v-for="cliente in clientes" v-bind:key="cliente.id" :value="cliente.id">{{cliente.id}} - {{cliente.razSoc}}</option>
                </select>                
              </div>
              <span  v-if="!item.colaborador_id"> Por favor, selecione o colaborador primeiro.</span> 
            </div>
            <div class="col-1-2">
              <div class="form-group">
                <label>Quantidade</label>
                <input class="form-control" autocomplete="chrome-off" type="number"  v-model="item.quantidade" />
                <div class="message">{{ validation.firstError('item.quantidade') }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button  class="button button-success" v-on:click="handleSubmit">Salvar</button>
          <button class="button" v-on:click="close">Fechar</button>
        </div>
        </CTab>
        </CTabs>
      </div>
    </div>
  </modal>
</template>

<script>
import SimpleVueValidation from "simple-vue-validator";
import CloseModal from "../../../../components/CloseModal";

// import vSelect from "vue-select";

const Validator = SimpleVueValidation.Validator.create({
  templates: {
    required: "Campo de preenchimento obrigatório",
  },
});

export default {
  name: "mEdit",
  components: { CloseModal },
  props: [
    "item",
    "close",
    "submit",
    "update",
    "mershandisings",
    "colaboradores",    
    "clientes",
    "handleClientes",
  ],
  data() {
    return {
      resultLimit: 100,
      activeTab: "dados",
      inputCliente: false
    };
  },
  validators: {
    "item.mershandising_id": (value) => Validator.value(value).required(),
    "item.colaborador_id": (value) => Validator.value(value).required(),
    "item.quantidade": (value) => Validator.value(value).required(),
  },
  computed: {},
  methods: {
    handleSubmit() {
      this.$validate().then((success) => {
        if (success) {
          if (!!this.item.id) request = this.update(this.item);
          if (!this.item.id) request = this.submit(this.item);
          request.catch((error) => {
            this.$swal({
              title: "Ops!",
              text: error.message,
              icon: "error",
            });
          });
        }
      });
    },
    updateClientes(val) {
      this.handleClientes(val.target.value);
    },
  },
};
</script>
