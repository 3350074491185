<template>
    <div>
        <div class="container-fluid">
            <div class="content">
                <div class="content-wrapper">
                    <Loading :isActive="loading"></Loading>
                     <CTabs variant="tabs" class="nav-tabs-boxed">
                      <CTab title="Merchandising">
                        <MershandisingList :items="mershandisings" :loadMershandising="loadMershandising" />
                      </CTab>
                      <CTab title="Ação">
                        <Acao :mershandisings="mershandisings" />
                      </CTab>
                      <CTab title="Estoque">
                        <Estoque :mershandisings="mershandisings" :colaboradores="colaboradores" />
                      </CTab>
                      <CTab title="Reserva">
                        <Reserva :mershandisings="mershandisings" :colaboradores="colaboradores"  />
                      </CTab>
                      <CTab title="Entrega">
                        <Entrega :mershandisings="mershandisings" />
                      </CTab>
                    </CTabs>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MershandisingList from "./components/MershandisingList/MershandisingList";
import Acao from "./components/Acao/Acao";
import Estoque from "./components/Estoque/Estoque";
import Reserva from "./components/Reserva/Reserva";
import Entrega from "./components/Entrega/Entrega";
import { get } from "../../helpers/apiRequest";

export default {
  name: "mershandising",
  components: {
    MershandisingList,
    Acao,
    Estoque,
    Reserva,
    Entrega,
  },
  data() {
    return {
      descPlural: "",
      descSingular: "",
      currentTab: "merchandising",
      loading: true,
      mershandisings: [],
      colaboradores: [],
      tabs: [
        {
          id: "merchandising",
          descricao: "Merchandising",
        },
        {
          id: "acao",
          descricao: "Ação",
        },
        {
          id: "estoque",
          descricao: "Estoque",
        },
        {
          id: "reserva",
          descricao: "Reserva",
        },
        {
          id: "entrega",
          descricao: "Entrega",
        },
      ],
    };
  },
  methods: {
    loadDescricaoModulo() {
      const { modulos } = JSON.parse(localStorage.getItem("app"));
      const { descPlural, descSingular } = modulos.find(
        (m) => m.nome === "ADMIN_MERSHANDISING"
      );
      this.descPlural = descPlural;
      this.descSingular = descSingular;
      this.loading = false;
    },
    changeTab(id) {
      this.currentTab = id;
    },
    loadMershandising() {
      get(`/admin/mershandising`)
        .then((json) => {
          this.mershandisings = json.itens;
          this.loading = false;
        })
        .catch((err) => (this.loading = false));
    },
    loadOperadores() {
      this.loading = true;
      get(`/admin/mershandising/colaboradores`)
        .then((json) => {
          this.colaboradores = json;
          this.loading = false;
        })
        .catch((err) => (this.loading = false));
    },
  },
  beforeMount() {
    this.loading = true;
    this.loadDescricaoModulo();
    this.loadMershandising();
    this.loadOperadores();
  },
};
</script>
