<template>
  <modal name="mInfo" height="auto" width="900" :clickToClose="true" >
    <CloseModal :close="close" />
    <div class="modalbox">
      <div class="modalbox-content" >
      <carousel
        :starting-image="1"
        :images="fotos"
        :show-progress-bar="true"
      ></carousel>
      </div>
    </div>
  </modal>
</template>

<script>
import CloseModal from "../../../../components/CloseModal";
import Carousel from "../../../../components/Carousel.vue";

export default {
  components: {
    CloseModal,
    Carousel,
  },
  props: ["fotos", "close"],
};
</script>
