<template>
  <modal name="mEdit" height="auto" width="900" :lazy="true" :scrollable="true" :clickToClose="false">
    <CloseModal :close="close" />
    <div class="modalbox">
      <div class="modalbox-content">
        <CTabs variant="tabs" class="nav-tabs-boxed">
        <CTab title="Mershandising">
        <div class="form" v-show="activeTab === 'dados'">
          <div class="xrow">
            <div class="col-1-3">
              <div class="form-group">
                <label>Sugestão</label>
                <input
                  class="form-control"
                  autocomplete="chrome-off"
                  v-model="item.sugestao"
                />
                <div class="message">
                  {{ validation.firstError("item.sugestao") }}
                </div>
              </div>
            </div>
            <div class="col-1-3">
              <div class="form-group">
                <label>Tipo</label>
                <input
                  class="form-control"
                  autocomplete="chrome-off"
                  v-model="item.tipo"
                />
                <div class="message">
                  {{ validation.firstError("item.tipo") }}
                </div>
              </div>
            </div>
            <div class="col-1-3">
              <div class="form-group">
                <label>Ativo</label>
                <select class="form-control">
                  <option value="1">Sim</option>
                  <option value="0">Não</option>
                </select>
                <div class="message">
                  {{ validation.firstError("item.ativo") }}
                </div>
              </div>
            </div>
          </div>
          <div class="xrow">
            <div class="col-1-1">
              <div class="form-group">
                <label>Descrição</label>
                <input
                  class="form-control"
                  autocomplete="chrome-off"
                  v-model="item.descricao"
                />
                <div class="message">
                  {{ validation.firstError("item.descricao") }}
                </div>
              </div>
            </div>
          </div>
          <div class="xrow">
            <div class="col-1-2 add-image">
                Adicionar Imagem
                <image-picker
                  label="Imagem "
                  placeholder="Colar URL ou clique ao lado para upload"
                  v-model="item.imagem"
                />
              <div class="message">
                {{ validation.firstError("item.imagem") }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="button button-success" v-on:click="handleSubmit">
            Salvar
          </button>
          <button class="button" v-on:click="close">Fechar</button>
        </div>
        </CTab>
        </CTabs>
      </div>
    </div>

  </modal>
</template>

<script>
import SimpleVueValidation from "simple-vue-validator";
import ImagePicker from "../../../../components/ImagePicker";
import CloseModal from "../../../../components/CloseModal";

const Validator = SimpleVueValidation.Validator.create({
  templates: {
    required: "Campo de preenchimento obrigatório",
  },
});

export default {
  name: "mEdit",
  components: { ImagePicker, CloseModal },
  props: ["item", "close", "submit", "update"],
  data() {
    return {
      filter: "",
      clientes: [],
      resultLimit: 100,
      activeTab: "dados",
      isModalVisible: false,
    };
  },
  validators: {
    "item.sugestao": (value) => Validator.value(value).required(),
    "item.descricao": (value) => Validator.value(value).required(),
    "item.tipo": (value) => Validator.value(value).required(),
    "item.imagem": (value) => Validator.value(value).required(),
    "item.ativo": (value) => Validator.value(value).required(),
  },
  computed: {},
  methods: {
    handleSubmit() {
      this.$validate().then((success) => {
        if (success) {
          if (!!this.item.id) request = this.update(this.item);
          if (!this.item.id) request = this.submit(this.item);
          request.catch((error) => {
            this.$swal({
              title: "Ops!",
              text: error.message,
              icon: "error",
            });
          });
        }
      });
    },
    handleUpload(type, extraInputs) {
      const self = this;
      event.preventDefault();
      this.loading = true;

      return upload(
        this.urls[type],
        "image",
        event.target.files[0],
        extraInputs
      )
        .then((response) => {
          this.loading = false;
          if (type !== "background") {
            self.images[type] = self.uploadedImages[type] +=
              "?cache=" + self.makeHash();
          }
        })
        .catch((error) => {
          this.loading = false;
          alert(error.message);
        });
    },
  },
};
</script>